import { useState } from "react"
import { Button } from "src/components/Button"
import Portal from "src/components/Portal"
import { COLORS, LAYOUT } from "src/constants"
import { FilterData, Option } from "src/constants/filters"
import CloseIcon from "src/svg/close.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled.div`
  background-color: ${COLORS.white};
  border-radius: 25px;
  border: 1px solid ${COLORS.bright};
  display: flex;
  overflow: hidden;
`
export const InputButton = styled.button`
  height: 48px;
  padding: 0 20px;
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ResetButton = styled.button`
  width: 50px;
  line-height: 0;
  svg {
    vertical-align: middle;
  }
`
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalBox = styled.div`
  background-color: ${COLORS.white};
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 800px;
  h1 {
    text-align: center;
    margin: -2px 0 2em 48px;
  }
  h2 {
    font-size: 1.2em;
  }
  @media (max-width: ${LAYOUT.tablet - 1}px) {
    height: 100%;
    border-radius: 0;
    h1 {
      margin: -10px 35px 35px 35px;
    }
    overflow: scroll;
  }
`
const CloseButton = styled.button`
  position: absolute;
  svg {
    width: 20px;
    height: 20px;
  }
`
const Options = styled.div`
  button {
    margin-bottom: 10px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`
const LinkButton = styled.button`
  color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
`
const Actions = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`

interface SelectorProps {
  title?: string
  options: Option[]
  selected: Option[]
  setSelected: (selected: Option[]) => void
}

const Selector = ({ title, options, selected, setSelected }: SelectorProps) => {
  return (
    <Options>
      {title && <h2>{title}</h2>}
      {options.map((option) => {
        const isSelected = selected.some(({ id }) => id === option.id)
        return (
          <Button
            $empty={!isSelected}
            key={option.id}
            onClick={() => {
              if (isSelected) {
                setSelected(selected.filter(({ id }) => id !== option.id))
              } else {
                setSelected(options.filter(({ id }) => id === option.id || selected.some((s) => id === s.id)))
              }
            }}
          >
            {option.name}
          </Button>
        )
      })}
    </Options>
  )
}

interface Props {
  placeholder: string
  title: string
  data: FilterData[]
  values: Option[][]
  onChange: ((values: Option<any>[]) => void)[]
}

const ModalSelect = ({ placeholder, title: modalTitle, data, values, onChange }: Props) => {
  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = useState<Option[][]>(values)

  const list = values.flat()

  const handleClear = () => {
    setSelected(data.map(() => []))
  }

  const toggle = (open: boolean) => {
    if (window.innerWidth < 800) {
      document.body.classList[open ? "add" : "remove"]("noscroll")
    }
    setOpen(open)
  }

  const handleSubmit = () => {
    onChange.forEach((callback, index) => {
      callback(selected[index])
    })
    toggle(false)
  }

  const handleReset = () => {
    onChange.forEach((callback) => {
      callback([])
    })
    handleClear()
  }

  return (
    <>
      <Container>
        <InputButton onClick={() => toggle(true)}>
          {list.length ? list.map(({ name }) => name).join(", ") : placeholder}
        </InputButton>

        {list.length ? (
          <ResetButton onClick={handleReset}>
            <CloseIcon />
          </ResetButton>
        ) : null}
      </Container>

      {isOpen && (
        <Portal>
          <Overlay
            onClick={({ target, currentTarget }) => {
              if (currentTarget === target) {
                toggle(false)
              }
            }}
          >
            <ModalBox>
              <CloseButton onClick={() => toggle(false)}>
                <CloseIcon />
              </CloseButton>

              <h1>{modalTitle}</h1>

              {data.map((item, index) => (
                <Selector
                  key={index}
                  title={item.title}
                  options={item.options}
                  selected={selected[index]}
                  setSelected={(choices: Option[]) => {
                    const newSelected = selected.slice()
                    newSelected[index] = choices
                    setSelected(newSelected)
                  }}
                />
              ))}

              <Actions>
                <LinkButton onClick={handleClear}>Tout effacer</LinkButton>
                <Button onClick={handleSubmit}>Appliquer</Button>
              </Actions>
            </ModalBox>
          </Overlay>
        </Portal>
      )}
    </>
  )
}

export default ModalSelect
