import { useEffect, useState } from "react"

export const usePropsState = <T>(getter: T | (() => T), deps?: any) => {
  const tuple = useState(getter)

  useEffect(() => {
    tuple[1](getter)
  }, [JSON.stringify(deps)]) // eslint-disable-line react-hooks/exhaustive-deps

  return tuple
}
