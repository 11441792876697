import { graphql } from "gatsby"
import React, { useEffect } from "react"
import AssociationCard from "src/cards/AssociationCard"
import { Block, Grid, HomeSection } from "src/components/Grid"
import { MissionFiltersSection } from "src/components/MissionFiltersSection"
import { useThemeFromDomainTheme } from "src/components/Theme"
import { AssociationFilterType } from "src/constants/filters"
import Layout from "src/layout"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const MissionDescription = styled(HomeSection)`
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  a {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
`

interface Data {
  assolib: {
    allDomains: Domain[]
    allDomainTagCounts: DomainTagCount[]
    allDomainPartners: DomainPartner[]
    allAssociations: Association[]
    Domain: { theme: DomainTheme }
  }
}

const MissionPage: GatsbyPage<Data> = ({ data: { assolib }, location }) => {
  const domain = assolib.allDomains[0]
  const updated = assolib.allAssociations.filter(({ absolute }) => absolute)
  const theme = useThemeFromDomainTheme(domain.theme)

  const areMissionsDisabled = !domain.mission_visible_on_frontend

  useEffect(() => {
    // Gatsby's createRedirect is not working well.
    // It shows a quick 404 page before redirecting to home.
    // In order to avoid this ugly flickering, we do it ourselves.
    if (areMissionsDisabled) {
      window.location.href = "/"
    }
  }, [areMissionsDisabled, location])

  if (areMissionsDisabled) {
    return null
  }

  return (
    <Layout theme={theme} domain={domain}>
      <MissionFiltersSection />
      {domain.mission_description && (
        <MissionDescription dangerouslySetInnerHTML={{ __html: domain.mission_description }} />
      )}
      <HomeSection>
        <h2>Dernières missions mises à jour</h2>
        <Grid>
          {updated.map((association) => (
            <Block $col={3} key={association.id}>
              <AssociationCard association={association} type={AssociationFilterType.Mission} />
            </Block>
          ))}
        </Grid>
      </HomeSection>
    </Layout>
  )
}

export default MissionPage

export const query = graphql`
  query {
    assolib {
      allDomainTagCounts {
        tag {
          id
          name
        }
      }
      allDomains {
        outlink_url
        home_about_image # needed for home_about_img to work!
        home_about_img {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        home_about_title
        home_about_text
        home_about_url
        mission_description
        mission_visible_on_frontend
        legal_notice_url
        facebook_url
        twitter_url
        instagram_url
        ...DomainThemeField
      }
      allDomainPartners {
        id
        name
        url
        logo_url
        logo_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      allAssociations(sortField: "updatedAt", type: Mission, sortOrder: DESC, perPage: 3) {
        ...SearchAssociationFields
      }
    }
  }
`
